/** @jsx jsx */
import React, { useState } from 'react';
import { Flex, Text, jsx, Box } from 'theme-ui';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { GatsbyImage } from 'gatsby-plugin-image';
import ArrowPrev from '~/assets/images/icons/arrow-prev.svg';
import ArrowNext from '~/assets/images/icons/arrow-next.svg';
import { CarouselWrapper } from './styles';
import AskAugustLink from './Link';

const FeaturedPostsCarousel = ({ posts, infiniteLoop = true }) => {
  const [arrowsVisible, setArrowsVisible] = useState(false);

  return (
    <CarouselWrapper
      onMouseEnter={() => setArrowsVisible(true)}
      onMouseLeave={() => setArrowsVisible(false)}
      sx={{
        '.slide': {
          background: 'none',
          height: ['40vh', '55vh'],
          position: 'relative',
          border: 'none',
        },
        '.control-dots': {
          display: ['none', 'flex', 'flex'],
        },
        display: 'flex',
        justifyContent: 'center',
        '&:hover': {
          '> button': { opacity: 1 },
        },
      }}
    >
      <Carousel
        showIndicators={posts.length > 1}
        showStatus={false}
        showArrows={false}
        showThumbs={false}
        autoPlay={!!infiniteLoop}
        infiniteLoop={infiniteLoop && posts.length > 1}
        sx={{
          width: ['100vw', '80%', '80%'],
          position: 'relative',
          mb: ['34px', 0, 0],
        }}
        renderArrowPrev={(clickHandler) => (
          <button
            type="button"
            onClick={clickHandler}
            sx={{
              background: 'white',
              borderRadius: '0px',
              border: 'none',
              position: 'absolute',
              top: '50%',
              left: -1,
              transform: 'translateY(-50%)',
              opacity: [0, arrowsVisible && posts.length > 1 ? '1' : '0'],
              transition: 'all 0.2s ease-in-out',
              zIndex: 1,
              svg: {
                width: '18px',
              },
            }}
          >
            <ArrowPrev />
          </button>
        )}
        renderArrowNext={(clickHandler) => (
          <button
            type="button"
            onClick={clickHandler}
            sx={{
              background: 'white',
              border: 'none',
              borderRadius: '0px',
              position: 'absolute',
              top: '50%',
              right: 0,
              transform: 'translateY(-50%)',
              opacity: [0, arrowsVisible && posts.length > 1 ? '1' : '0'],
              transition: 'all 0.2s ease-in-out',
              zIndex: 1,
              svg: {
                width: '18px',
              },
            }}
          >
            <ArrowNext />
          </button>
        )}
      >
        {posts.map((post, index) => (
          <Flex
            key={`${post.question}-${index}`}
            sx={{
              outline: 'none',
              position: 'relative',
              flexDirection: 'column',
              bottom: '0',
              height: '100%',
            }}
          >
            <AskAugustLink
              key={post.slug}
              slug={post.slug}
              sx={{
                display: 'block',
              }}
            >
              <Box
                sx={{
                  height: '100%',
                  '& > .gatsby-image-wrapper': { height: ['40vh', '55vh', '60vh'], width: '100%' },
                }}
              >
                <GatsbyImage
                  image={post.heroImage.gatsbyImageData}
                  key={index}
                  alt={post.question}
                />
              </Box>
              <Flex
                sx={{
                  position: 'absolute',
                  flexDirection: 'column',
                  bottom: ['20px', '40px', '30px'],
                }}
              >
                <Text
                  sx={{
                    backgroundColor: 'chatelle',
                    p: ['8px 8px', '8px 12px', '8px 12px'],
                    fontSize: [4, 7, 9],
                    fontWeight: 'bold',
                    overflowWrap: 'normal',
                    width: ['auto', 'auto', 'max-content'],
                    maxWidth: '75%',
                    textAlign: ['left'],
                  }}
                >
                  {post.question}
                </Text>
                <Text
                  sx={{
                    backgroundColor: 'white',
                    p: '8px 12px',
                    fontStyle: 'italic',
                    fontSize: [4, 6, 7],
                    textAlign: ['left'],
                    maxWidth: ['none', '70%', '70%'],
                    mb: [0, 0, '8px'],
                  }}
                >
                  {post.previewText ?? null}
                </Text>
              </Flex>
            </AskAugustLink>
          </Flex>
        ))}
      </Carousel>
    </CarouselWrapper>
  );
};

export default FeaturedPostsCarousel;
