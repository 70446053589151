/** @jsx jsx */
import { Flex, jsx } from 'theme-ui';
import { useRef } from 'react';
import useOnScreen from '~/hooks/components/use-on-screen';

const FadeIn = ({ children, column }) => {
  const ref = useRef();
  const onScreen = useOnScreen(ref, '0px', true);
  return (
    <Flex sx={{ width: '100%' }} className={`fade-in-up ${onScreen ? 'visible' : ''}`} ref={ref}>
      {children}
    </Flex>
  );
};

export default FadeIn;
