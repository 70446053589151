/** @jsx jsx */
import { Container, Box, jsx } from 'theme-ui';
import _ from 'lodash';

import Spacer from '~/components/Generic/Layout/Spacer';
import ImageMarquee from '~/components/Generic/Marquee/Image';
// import Breadcrumb from '~/components/AskAugust/Breadcrumb';
import TextMarquee from '~/components/Generic/Marquee/Text';
import useWindowSize from '~/hooks/components/use-window-size';
import Pagination from '~/components/Generic/Pagination/Pagination';

const AskAugustLayout = ({ meetTheStaff, isBlog, children, numPages, currentPage, ...props }) => {
  const { width } = useWindowSize();
  const imageNodes = _.map(meetTheStaff.marqueeImages, (marqueeImage) => ({
    fluid: marqueeImage.image.fluid,
    description: marqueeImage.altText,
    file: marqueeImage.image.file,
    link: marqueeImage.link,
    label: marqueeImage.label,
    gatsbyImageData: marqueeImage.image.gatsbyImageData,
  }));
  // TODO: Replace breadcrumb with some other form of nav
  return (
    <Container {...props} sx={{ position: 'relative' }}>
      {/* <Breadcrumb /> */}
      {children}
      <Spacer height={['80px', '50px', '40px']} />
      {isBlog && <Pagination numPages={numPages} currentPage={currentPage} url="/ask-august" />}
      <Spacer height={['60px', '70px', '80px']} />
      <ImageMarquee images={imageNodes} heading="Meet our Medical Board" hasLabels spinner />
      <Box
        sx={{
          display: width < 1279 ? 'none' : 'block',
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          pointerEvents: 'none',
        }}
      >
        <Box
          sx={{
            width: '129%',
            height: '50px',
            position: 'absolute',
            right: '50px',
            top: '100vh',
            transform: 'rotate(90deg)',
            transformOrigin: '100% 100%',
            '& > div': {
              height: '100%',
            },
          }}
        >
          <TextMarquee
            text="askAugust"
            backgroundColor="chatelle"
            textColor="alizarinCrimson"
            borderColor="#b5afc2"
            askAugust
            linkTo={{
              url: '/ask-august',
            }}
          />
        </Box>
      </Box>
    </Container>
  );
};

export default AskAugustLayout;
