/** @jsx jsx */
import { useState } from 'react';
import { Flex, Input, jsx, IconButton } from 'theme-ui';
import { navigate } from 'gatsby';

import { useSearch } from '~/contexts/SearchContext';
import ArrowRight from '~/assets/images/icons/arrow-right.svg';

const BasicSearch = () => {
  const [queryString, setQueryString] = useState('');
  const { setSearch } = useSearch();

  const handleSubmit = (e) => {
    e.preventDefault();
    setSearch(queryString);
    navigate('/ask-august/questions');
  };
  return (
    <Flex
      as="form"
      onSubmit={(e) => handleSubmit(e)}
      sx={{
        position: 'relative',
        transform: ['scale(0.67)', 'scale(0.75)', 'none'],
        width: ['150%', null, 'auto'],
        maxWidth: ['700px', null, 'none'],
      }}
    >
      <Input
        name="search"
        ariaLabel="question search"
        placeholder="Search for anything e.g. pms, pain, mental health, etc..."
        onChange={(e) => setQueryString(e.target.value)}
        sx={{
          height: '7.5rem',
          width: ['100%', null, '55rem'],
          border: '1px solid #e3232a',
          borderRadius: '1.8rem',
          color: 'alizarinCrimson',
          pl: '2.7rem',
          fontSize: [4, 6, 8],

          '&::placeholder': {
            color: 'alizarinCrimson',
            opacity: 0.7,
            fontSize: [4, 6, 8],
          },
        }}
      />
      <IconButton
        onClick={(e) => handleSubmit(e)}
        sx={{
          display: ['none', 'block'],
          padding: 0,
          position: 'absolute',
          width: '2.4rem',
          right: '2.4rem',
          top: '50%',
          transform: 'translateY(-50%)',
          outline: 'none',
          cursor: "url('/images/blood.svg'), pointer",
        }}
      >
        <ArrowRight sx={{ width: '100%  ' }} />
      </IconButton>
    </Flex>
  );
};

export default BasicSearch;
