/** @jsx jsx */

import { jsx } from 'theme-ui';
import { graphql, useStaticQuery } from 'gatsby';

import Layout from '~/components/Layout';
import Metadata from '~/components/Metadata';
import AskAugust from '~/components/Pages/AskAugust';

// This is the Main Ask August Page, different from the "Questions page" which will be deprecated

const AskAugustPageTemplate = ({ data, pageContext }) => {
  const {
    allPosts: { nodes: allPostNodes },
    topics: { nodes: topicNodes },
    page,
  } = data;
  const { numPages, currentPage } = pageContext;
  return (
    <Layout>
      <Metadata
        title="Ask August"
        description="Your questions, answered by our team of medical professionals"
      />
      <AskAugust
        page={page}
        allPosts={allPostNodes.filter((post) => !post.question.includes('Unification'))}
        topics={topicNodes}
        numPages={numPages}
        currentPage={currentPage}
      />
    </Layout>
  );
};

export const query = graphql`
  query ($limit: Int!, $skip: Int!) {
    page: contentfulPage(slug: { eq: "ask-august" }) {
      ...ContentPageFragment
    }
    allPosts: allContentfulAskAugustQuestion(limit: $limit, skip: $skip) {
      nodes {
        ...AskAugustQuestionFragment
      }
    }
    topics: allContentfulAskAugustTopic {
      nodes {
        ...AskAugustTopicFragment
      }
    }
  }
`;

export default AskAugustPageTemplate;
