import { useEffect, useState } from 'react';

const useOnScreen = (ref, rootMargin = '0px', displayOnce = false) => {
  // State and setter for storing whether element is visible
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    const { current } = ref;
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Update our state when observer callback fires
        setIntersecting(entry.isIntersecting);
        if (entry.isIntersecting && displayOnce) {
          observer.unobserve(current);
        }
      },
      {
        rootMargin,
      }
    );
    if (ref.current) {
      observer.observe(current);
    }
    return () => {
      observer.unobserve(current);
    };
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return isIntersecting;
};

export default useOnScreen;
