/** @jsx jsx */
import React, { useState, useEffect, useRef } from 'react';
import { Flex, jsx, Text } from 'theme-ui';
import _ from 'lodash';
import AskAugustPostPreview from '~/components/AskAugust/PostPreview';

const AskAugustList = ({ listItems }) => {
  const sampleSize = 9;
  const [renderedItems, setRenderedItems] = useState([]);
  const [pageNum, setPageNum] = useState(1);
  const [loadMore, setLoadMore] = useState(false);

  // Set a ref for the loading div
  const loadRef = useRef();

  const handleObserver = (entities) => {
    const target = entities[0];
    if (target.isIntersecting) {
      setLoadMore(true);
    }
  };

  // Initialize the intersection observer API
  useEffect(() => {
    const options = {
      // Null means we default to the viewport
      root: null,
      // The extra space around the element that will trigger the callback
      rootMargin: '40px',
      // How much of the target must be visible for the callback to run 0.5 = 50%
      threshold: 0.5,
    };
    const observer = new IntersectionObserver(handleObserver, options);
    // .current basically just says that the element the ref is attached to has rendered
    if (loadRef.current) {
      observer.observe(loadRef.current);
    }
  }, [loadRef]);

  useEffect(() => {
    if (loadMore) {
      setPageNum(pageNum + 1);
      setLoadMore(false);
    }
  }, [loadMore]);

  useEffect(() => {
    const items = _.slice(listItems, 0, sampleSize * pageNum);
    setRenderedItems(items);
  }, [listItems, pageNum]);

  return (
    <>
      <Flex
        sx={{
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
        }}
      >
        {renderedItems.length > 0 ? (
          _.map(renderedItems, (post, idx) => (
            <AskAugustPostPreview key={`ask-august-post-preview-${idx}`} post={post} />
          ))
        ) : (
          <Text
            sx={{ fontSize: [7, 8, 9], textTransform: 'uppercase', fontWeight: 'bold', my: '24px' }}
          >
            No posts to show in this category
          </Text>
        )}
      </Flex>
      {/* This below determines when to load more for the infinite scroll */}
      <Flex ref={loadRef} sx={{ justifyContent: 'center', mt: ['2.5rem', '3.5rem', '4.5rem'] }} />
    </>
  );
};
export default AskAugustList;
