import React from 'react';

// Render inline SVG with fallback non-svg images
const SvgImage = ({ svgImage, alt, ...props }) => {
  const { svg, file } = svgImage;
  if (file && file.contentType === 'image/svg+xml') {
    return <div dangerouslySetInnerHTML={{ __html: svg.content }} {...props} />;
  }
  return null;
};

export default SvgImage;
