/* eslint-disable arrow-body-style */
/** @jsx jsx */
import { Flex, Box, Text, jsx } from 'theme-ui';
import { GatsbyImage } from 'gatsby-plugin-image';
import AskAugustLink from '~/components/AskAugust/Link';
import FadeIn from '../Generic/Layout/FadeIn';
import ArrowRight from '~/assets/images/icons/arrow-right.svg';
import AugustA from '~/assets/images/A-logo.svg';

const AskAugustPostPreview = ({ post }) => {
  const { thumbnail, slug, topics, question, previewText, ourAnswer } = post;

  const generatePreviewText = (text) => {
    return text.split(' ', 21).length >= 20
      ? // eslint-disable-next-line prefer-template
        text.split(' ', 20).join(' ') + '...'
      : text.split(' ', 20).join(' ');
  };

  return (
    <Flex
      sx={{
        mb: ['34px', '44px', '44px'],
        width: ['100%', '80%', '80%'],
        flexDirection: 'row',
        maxHeight: ['250px'],
      }}
    >
      <FadeIn>
        <AskAugustLink key={slug} slug={slug}>
          {/* Logo Container */}
          <Flex
            sx={{
              display: ['none', 'flex', 'flex'],
              width: '15%',
              height: '100%',
            }}
          >
            <Box
              sx={{
                '& > svg': {
                  width: ['40px', null, '80px'],
                  height: ['36px', null, '72px'],
                  mt: '20%',
                },
              }}
            >
              <AugustA />
            </Box>
          </Flex>
          {/* Image & Text container */}
          <Flex
            sx={{
              width: ['100%', '80%', '70%'],
              flexDirection: ['column', 'row', 'row'],
            }}
          >
            {/* Image */}
            {thumbnail && (
              <Box
                sx={{
                  height: ['12rem', '100%'],
                  width: '100%',
                  '& > .gatsby-image-wrapper': { height: '100%', width: '100%' },
                }}
              >
                <GatsbyImage image={thumbnail.gatsbyImageData} sx={{ objectFit: 'cover' }} />
              </Box>
            )}
            {/* Text Etc container */}
            <Flex
              sx={{
                flexDirection: 'column',
                width: ['100%', '130%', '130%'],
                ml: ['0px', '20px', '20px'],
                mt: ['-27px', 0, 0],
                zIndex: 2,
              }}
            >
              {/* Topics Bubble */}
              <Text
                sx={{
                  color: 'canary',
                  fontSize: [3, 6, 7],
                  fontWeight: 'bold',
                  fontStyle: 'italic',
                  backgroundColor: 'chatelle',
                  borderRadius: [0, '12px', '12px'],
                  width: 'max-content',
                  padding: '6px 12px',
                  mb: ['12px', '24px', '24px'],
                }}
              >
                {topics[0] ?? null}
              </Text>
              {/* Title */}
              <Text
                sx={{
                  color: 'alizarinCrimson',
                  fontSize: [9, 10, 11],
                  fontWeight: 'bold',
                }}
              >
                {question}
              </Text>
              <Text sx={{ mt: '10px', pr: '8px', overflow: 'hidden' }}>
                {previewText || (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: generatePreviewText(ourAnswer.childMarkdownRemark.html),
                    }}
                  />
                )}
              </Text>

              <Text
                sx={{
                  fontWeight: 'bold',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  ml: '4px',
                  width: '100%',
                  textTransform: 'uppercase',
                  fontSize: '10px',
                  mt: ['0px', '4px', '-4px'],
                }}
              >
                Read more{' '}
                <ArrowRight sx={{ transform: 'rotate(-45deg)', ml: '4px', width: '15px' }} />
              </Text>
            </Flex>
          </Flex>
        </AskAugustLink>
      </FadeIn>
    </Flex>
  );
};
export default AskAugustPostPreview;
