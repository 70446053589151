/** @jsx jsx */
import { Flex, jsx, Button } from 'theme-ui';

const ActivePostsSelector = ({ setActivePostsFilter, activePostsFilter, activePostsText }) => (
  <Flex
    sx={{
      alignItems: 'center',
      width: ['100%', '80%', 'max-content'],
      justifyContent: ['space-evenly'],
      ml: [0, 0, '2%'],
      flexWrap: ['wrap', 'no-wrap', 'nowrap'],
      borderTop: ['1px solid rgba(227, 35, 42, 0.2)', '1px solid rgba(227, 35, 42, 0.2)', 'none'],
      borderBottom: [
        '1px solid rgba(227, 35, 42, 0.2)',
        '1px solid rgba(227, 35, 42, 0.2)',
        'none',
      ],
      pt: '12px',
      pb: '4px',
    }}
  >
    {Object.entries(activePostsText).map((item, idx) => (
      <Button
        key={`post-selector-${idx}`}
        onClick={() => setActivePostsFilter(item[0])}
        sx={{
          color: activePostsFilter === item[0] ? 'canary' : 'alizarinCrimson',
          backgroundColor: activePostsFilter === item[0] ? 'alizarinCrimson' : 'none',
          padding: ['8px', '6px 12px', '6px 12px'],
          borderRadius: '12px',
          mx: '4px',
          mb: ['8px', '8px', 0],
          fontWeight: 'bold',
          border: 'none',
        }}
      >
        {item[1]}
      </Button>
    ))}
  </Flex>
);
export default ActivePostsSelector;
