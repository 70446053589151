/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Link } from 'gatsby';

const AskAugustLink = ({ slug, toTopic = false, children, ...props }) => {
  const url = toTopic ? `/ask-august/topics/${slug}` : `/ask-august/questions/${slug}`;
  return (
    <Link sx={{ display: 'flex', width: '100%' }} to={url} {...props}>
      {children}
    </Link>
  );
};

export default AskAugustLink;
