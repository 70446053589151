/** @jsx jsx */
import { jsx, Flex, Box } from 'theme-ui';
import { Link } from 'gatsby';
import ArrowNext from '~/assets/images/icons/arrow-next.svg';
import ArrowPrev from '~/assets/images/icons/arrow-prev.svg';

const Pagination = ({ numPages, currentPage, url }) => {
  const firstPage = url;
  const lastPage = `${url}/${numPages}`;
  return (
    <Flex
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {currentPage !== 1 && (
        <Link
          to={firstPage}
          sx={{
            display: 'flex',
            alignItems: 'center',
            marginX: ['0.5rem', '0.5rem', '1rem'],
          }}
        >
          <ArrowPrev />
          <Box sx={{ marginBottom: '0.25rem', marginX: ['0.5rem', '0.5rem', '1rem'] }}>
            First ...
          </Box>
        </Link>
      )}
      {Array.from({ length: numPages }, (v, i) => i + 1)
        .filter((i) =>
          currentPage < 5
            ? i < 11
            : currentPage > numPages - 11
            ? i > numPages - 11
            : i <= currentPage + 5 && i >= currentPage - 5
        )
        .map((i) => (
          <Link
            sx={{
              marginX: ['0.25rem', '0.5rem', '0.5rem'],
              paddingX: ['0.5rem', '1rem', '1rem'],
              color: currentPage === i ? 'chatelle' : '',
              pointerEvents: currentPage === i && 'none',
              display: [Math.abs(currentPage - i) > 1 ? 'none' : 'flex', 'flex'],
              alignItems: 'center',
              marginBottom: '0.25rem',
            }}
            to={i === 1 ? `${url}` : `${url}/${i}`}
          >
            {i}
          </Link>
        ))}
      {currentPage !== numPages && (
        <Link
          to={lastPage}
          sx={{
            display: 'flex',
            alignItems: 'center',
            marginX: ['0.5rem', '0.5rem', '1rem'],
          }}
        >
          <Box sx={{ marginBottom: '0.25rem', marginX: ['0.5rem', '0.5rem', '1rem'] }}>
            ... Last
          </Box>
          <ArrowNext />
        </Link>
      )}
    </Flex>
  );
};
export default Pagination;
