/** @jsx jsx */
import { useState, useEffect } from 'react';
import { Container, Flex, jsx, Heading } from 'theme-ui';
import { graphql, useStaticQuery } from 'gatsby';
import AskAugustList from '~/components/AskAugust/List';
import FeaturedPostsCarousel from '~/components/AskAugust/FeaturedPostsCarousel';
import ActivePostsSelector from '~/components/AskAugust/ActivePostsSelector';
import SvgImage from '~/components/Generic/Image/SVG';
import RichTextRenderer from '~/components/Content/RichText';
import BasicSearch from '~/components/AskAugust/BasicSearch';
import AskAugustLayout from './Layout';

const AskAugust = ({ page, allPosts, numPages, currentPage }) => {
  const [sampleQuestions, setSampleQuestions] = useState([]);
  const [activePostsFilter, setActivePostsFilter] = useState('latest');

  // This we can use but will need to refactor for pagination so it doesn't load everything
  // TODO refactor for dynamic API calls / incremental loading

  const checkPostDate = (post) =>
    post.date !== null ? new Date(post.date).getTime() : new Date(post.createdAt).getTime();

  useEffect(() => {
    if (activePostsFilter === 'latest') {
      setSampleQuestions(allPosts.sort((a, b) => checkPostDate(b) - checkPostDate(a)));
    } else if (activePostsFilter === 'questions') {
      setSampleQuestions(
        allPosts
          .filter((post) => !post.isBlogPost)
          .sort((a, b) => checkPostDate(b) - checkPostDate(a))
      );
    } else {
      setSampleQuestions(
        allPosts
          .filter((post) => post.isBlogPost && post.type === activePostsFilter)
          .sort((a, b) => checkPostDate(b) - checkPostDate(a))
      );
    }
  }, [activePostsFilter]);

  const activePostsText = {
    latest: 'The Latest',
    questions: 'Q & A',
  };

  const data = useStaticQuery(graphql`
    query {
      askAugustCrimson: contentfulAsset(title: { eq: "ask-august-crimson-svg" }) {
        svg {
          content
        }
        file {
          contentType
          url
        }
      }
    }
  `);
  const { askAugustCrimson } = data;

  const { sections } = page;

  const sectionZero = sections[0];
  const meetTheStaff = sections[1];

  return (
    <AskAugustLayout
      meetTheStaff={meetTheStaff}
      numPages={numPages}
      currentPage={currentPage}
      isBlog
    >
      <Flex sx={{ flexDirection: 'column', alignItems: 'center' }}>
        <Heading
          variant="h2"
          sx={{
            height: ['6.4rem', '8.6rem'],
            width: ['20rem', '27rem'],
            mt: '45px',
            mb: '2.5rem',
          }}
        >
          <Heading as="h1" sx={{ height: '0px', opacity: '0' }}>
            Ask August
          </Heading>
          <SvgImage svgImage={askAugustCrimson} />
        </Heading>
        <Heading
          as="h3"
          variant="h3"
          sx={{
            width: ['32.5rem', '47.5rem', '62rem'],
            fontSize: [3, 5, 9],
            textTransform: 'none',
            textAlign: 'center',
            mb: '4rem',
          }}
        >
          <RichTextRenderer richText={sectionZero.subheading} />
        </Heading>
        <BasicSearch />
      </Flex>
      <Container sx={{ maxWidth: ['100%', '99%', '1440px'], mt: ['2rem', '4rem', '4rem'] }}>
        <FeaturedPostsCarousel posts={allPosts.filter((post) => post.isFeatured).slice(0, 3)} />
        <Flex
          sx={{
            justifyContent: ['center', 'flex-start', 'flex-start'],
            mt: ['-2rem', '4rem', '3rem'],
            width: ['100%', '100%', '80%'],
            ml: ['0', '8%', '8%'],
            mb: ['2.5rem', '2.5rem', '4.5rem'],
          }}
        >
          <ActivePostsSelector
            activePostsFilter={activePostsFilter}
            setActivePostsFilter={setActivePostsFilter}
            activePostsText={activePostsText}
          />
        </Flex>
        <AskAugustList listItems={sampleQuestions} />
      </Container>
    </AskAugustLayout>
  );
};

export default AskAugust;
