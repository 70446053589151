/** @jsx jsx */
import { Link } from 'gatsby';
import { useEffect, useRef, useState } from 'react';
import { Box, Text, jsx, useThemeUI, Flex } from 'theme-ui';

const TextMarquee = ({
  text,
  backgroundColor,
  textColor,
  outlinedText,
  borderColor,
  linkTo,
  numSpans = 30,
  fontWeight = 'regular',
  symbol,
  reverseDirection,
  askAugust,
  longText = false,
  height = '3rem',
  symbolFontSize,
  pauseOnHover = false,
  setToolTipShown,
  ...props
}) => {
  const { theme } = useThemeUI();

  const componentWidthRef = useRef(null);
  const [animationSpeed, setAnimationSpeed] = useState(20);

  useEffect(() => {
    setAnimationSpeed(componentWidthRef?.current?.offsetWidth / 72); // number 72 is a number to get 20 sec speed on a 1440p screen (1140/72 ~~ 20), so to match all marquee speeds on the same page we divide the marquee widht by 72
  }, [componentWidthRef?.current?.offsetWidth]);

  return linkTo?.external ? (
    <a
      href={linkTo.url}
      target="__blank"
      rel="noopener noreferrer"
      sx={{
        height: ['30px', '40px', '55px'],
        display: 'block',
        width: '100%',
        overflow: 'hidden',
        boxSizing: 'border-box',
        position: 'relative',
        margin: '0 auto',
        backgroundColor,
        zIndex: 9900,
        border: `${borderColor ? `1px solid ${borderColor}` : 'none'}`,
      }}
      {...props}
    >
      <Box
        sx={{
          backgroundColor: 'inherit',
          display: 'block',
          width: '1000%',
          height: '100%',
          position: 'absolute',
          top: 0,
          // animation: reverseDirection
          //   ? ['marquee 15s linear  reverse  infinite', 'marquee 20s linear reverse infinite']
          //   : ['marquee 15s linear infinite', 'marquee 20s linear infinite'],
          span: {
            display: 'inline-block',
            pt: ['2px', '5px'],
          },
          '& > span > div': {
            fontWeight,
            color: outlinedText ? backgroundColor : textColor,
            WebkitTextStroke: `${outlinedText ? `2px ${textColor}` : 'none'}`,
            textStroke: `${outlinedText ? `2px ${textColor}` : 'none'}`,
            display: 'inline-block',
            pt: ['0.6rem', '1rem', '1.2rem'],
          },
        }}
      >
        {[...Array(numSpans)].map((e, i) => (
          <span key={i}>
            <Text sx={{ mx: '3rem', color: 'chatelle', fontSize: [3, 4, 13] }}>{text}</Text>
          </span>
        ))}
      </Box>
    </a>
  ) : (
    <Box
      as={linkTo ? Link : Box}
      to={linkTo?.url}
      ref={componentWidthRef}
      sx={{
        height: longText ? height : ['30px', '40px', '55px'],
        display: 'block',
        width: longText ? 'auto' : '100%',
        overflow: 'hidden',
        boxSizing: 'border-box',
        position: 'relative',
        margin: longText ? ['0 -1rem', '0 -1rem', '0'] : '0 auto',
        backgroundColor,
        border: `${borderColor ? `1px solid ${borderColor}` : 'none'}`,
      }}
      onMouseEnter={() => setToolTipShown && setToolTipShown(true)}
      onMouseLeave={() => setToolTipShown && setToolTipShown(false)}
      {...props}
    >
      <Box
        sx={{
          backgroundColor: 'inherit',
          display: 'block',
          width: '1000%',
          height: '100%',
          position: 'absolute',
          top: 0,
          animation: reverseDirection
            ? ['marquee 15s linear  reverse  infinite', 'marquee 20s linear reverse infinite']
            : [
                `marquee ${animationSpeed * 1.5}s linear infinite`,
                `marquee ${animationSpeed * 1.5}s linear infinite`,
                `marquee ${animationSpeed}s linear infinite`,
              ],
          '&:hover': {
            animationPlayState: pauseOnHover ? 'paused' : 'unset',
          },
          span: {
            display: 'inline-block',
          },
          '& > span > div': {
            fontWeight,
            fontSize: [3, 5, 13],
            color: outlinedText ? backgroundColor : textColor,
            WebkitTextStroke: `${outlinedText ? `2px ${theme.colors.alizarinCrimson}` : 'none'}`,
            display: 'flex',
          },
        }}
      >
        {[...Array(numSpans)].map((e, i) => (
          <span key={i}>
            <Flex
              sx={{
                display: 'flex',
                width: longText ? '100%' : ['15rem', '180px', askAugust ? '180px' : '280px'],
                height: longText ? height : ['30px', '40px', '50px'],
                mx: longText ? 0 : ['0.6rem'],
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                '& > span': {
                  fontSize: longText ? ['1.2rem', '1.2rem', '1.3rem'] : [3, 5, 13],
                },
              }}
              key={`${i}-Flex`}
            >
              <Text key={`${i}-Text`} sx={{ mx: longText && symbolFontSize ? '2rem' : 0 }}>
                {text}
              </Text>
              <p
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  fontSize: symbolFontSize || ['30px', '40px', '50px'],
                  height: longText ? height : ['30px', '40px', '50px'],
                  pb: longText ? 0 : ['7px', '8px', '10px'],
                  mx: longText ? 0 : ['3%', '3%', '1%'],
                }}
                key={`${i}-P`}
              >
                {symbol}
              </p>
            </Flex>
          </span>
        ))}
      </Box>
    </Box>
  );
};

export default TextMarquee;
